import React from "react";
import styled from "styled-components";

interface NavSection {
  name: string;
  linkTo: string;
}

const Nav = () => {
  const sections: NavSection[] = [
    { name: "Hjem", linkTo: "#" },
    { name: "Prosjekter", linkTo: "#projects" },
    { name: "Om meg", linkTo: "#about" },
    { name: "Kontakt", linkTo: "#contact" },
    { name: "Blogg", linkTo: "#blog" }
  ];

  return (
    <NavWrapper>
      {sections.map(s => (
        <a key={s.name + "_nav"} href={s.linkTo}>
          {s.name}
        </a>
      ))}
    </NavWrapper>
  );
};

const NavWrapper = styled.nav`
  width: 100%;
  padding: 1em 1.5em 0 1.5em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 650px) {
    max-width: 50vw;
  }
`;

export default Nav;
