import { IProject } from "./types";

export const lagerstyring: IProject = {
  title: "Lagerstyring",
  year: "2019-" + new Date().getFullYear(),
  description:
    "Enkelt lagersystem med registrering av bestillinger og salg, og historikk over alle endringer",
  tech: "React, Redux, Typescript, Firebase",
  color: "#E3AA39",
  imgUrl:
    "https://firebasestorage.googleapis.com/v0/b/eystein-dev.appspot.com/o/lagerstyring_img.jpg?alt=media&token=699b23ad-6742-46f8-b3ee-50a96fbb1ecf",
  githubUrl: "https://github.com/EysteinKS/Simple-Inventory-Manager",
  linkTo: "https://lager.eystein.dev",
  favicon: "https://lager.eystein.dev/favicon.ico"
};
