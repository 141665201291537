import React from "react";
import { PageWrapper, PageTitle } from "../templates/page";

const Contact = () => {
  return (
    <PageWrapper id="contact">
      <PageTitle>Contact</PageTitle>
    </PageWrapper>
  );
};

export default Contact;
