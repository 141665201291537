import { useEffect, RefObject } from "react";

const useScrollTo = (hash: string, ref: RefObject<HTMLElement>) => {
  //Scroll to element on page load
  useEffect(() => {
    const pageHash = "#" + hash;
    const currentHash = window.location.hash;
    if (!currentHash) return;
    if (currentHash === pageHash && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: "smooth"
      });
    }
  });
};

export default useScrollTo;
