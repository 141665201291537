interface IColor {
  light: string;
  dark: string;
}

const background = {
  primary: {
    light: "",
    dark: "#262629"
  },
  secondary: {
    light: "",
    dark: "#31313c",
    hover: "#2d2d33"
  },
  header: {
    light: "",
    dark: "#2b2b2f"
  },
  element: {
    light: "",
    dark: "#424248"
  }
};

const text = {
  primary: {
    light: "",
    dark: "#ffd384"
  },
  secondary: {
    light: "",
    dark: "#d9d6e8"
  },
  hover: {
    light: "",
    dark: "#dcdcdcb5"
  },
  visited: {
    light: "",
    dark: "#b4adef"
  }
};

export default {
  background,
  text
};
