import React, { Suspense } from "react";
import styled, { css } from "styled-components";
import { IProject } from "../types";
import Icons from "../Icons";
import colors from "../styles/colors";

type CardSizes = "s" | "m";

interface ProjectCardProps {
  size: CardSizes;
  project: IProject;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ size, project }) => {
  const goToLink = () => {
    if (project.linkTo) {
      window.location.href = project.linkTo;
    }
  };

  return (
    <CardWrapper size={size} details={project.color}>
      <Suspense fallback={() => <div />}>
        <CardImage src={project.imgUrl} alt="Image" onClick={goToLink} />
      </Suspense>
      <CardTitle onClick={goToLink}>
        {project.favicon && <img src={project.favicon} alt="i" />}{" "}
        {project.title}
      </CardTitle>
      <CardYears>{project.year}</CardYears>
      <CardDescription>{project.description}</CardDescription>
      <CardFooter size={size}>
        {project.tech ? (
          <CardTech>
            <Icons.Code /> {project.tech} <Icons.CodeAlt />
          </CardTech>
        ) : (
          <div />
        )}
        {project.githubUrl ? (
          <CardLink title="Github" href={project.githubUrl} target="_blank">
            <Icons.GithubLogo />
          </CardLink>
        ) : (
          <div />
        )}
        {project.linkTo && (
          <CardLink title={project.title} href={project.linkTo} target="_blank">
            <Icons.LinkExternal />
          </CardLink>
        )}
      </CardFooter>
    </CardWrapper>
  );
};

const CardWrapper = styled.div<{ size: CardSizes; details: string }>`
  height: 300px;
  max-width: ${props => (props.size === "s" ? "200px" : "500px")};
  margin: 0 auto;
  display: grid;
  grid-template-rows: ${props =>
    props.size === "s" ? "7fr 2fr 1fr 6fr 2fr" : "2fr 1fr 6fr 2fr"};
  background: ${colors.background.secondary.dark};
  box-shadow: 5px 5px 15px 0px #0002;
  border-bottom: 2px solid ${props => props.details + "f0"};

  p {
    color: ${colors.text.secondary.dark + "f0"};
  }

  footer > p {
    margin: ${props => (props.size === "s" ? "0 0.5rem" : "0")};
    svg {
    }
  }

  h2 {
    color: ${props => props.details + "f0"};
    font-size: 1.17em;
  }

  h6 {
    color: ${props => props.details + "d6"};
  }

  :hover {
    box-shadow: 10px 10px 15px 0px #0002;
    color: ${colors.text.hover.dark};
    border-color: ${props => props.details};

    img {
      filter: none;
    }

    h2 {
      color: ${props => props.details};
    }

    p {
      color: ${colors.text.secondary.dark};
    }
  }

  ${props =>
    props.size === "m" &&
    css`
      height: 200px;
      grid-template-columns: 2fr 3fr;
      h2 {
        font-size: 1.5em;
      }
      img {
        grid-row: span 4;
      }
    `}
`;

const CardImage = styled.img`
  object-fit: cover;
  object-position: top left;
  height: 100%;
  width: 100%;
  max-width: 200px;
  filter: grayscale(0.1);

  :hover {
    cursor: pointer;
  }
`;

const CardTitle = styled.h2`
  color: ${colors.text.secondary.dark};
  padding-left: 1rem;
  align-self: end;

  :hover {
    cursor: pointer;
  }

  img {
    width: 1rem;
  }
`;

const CardYears = styled.h6`
  padding: 0 1rem;
  align-self: center;
`;

const CardDescription = styled.p`
  font-size: 0.8em;
  padding: 0.5rem 1rem 0;
  align-self: start;
`;

const CardTech = styled.p`
  font-size: 0.7em;
  align-self: end;

  svg {
    height: 14px;
    width: 14px;
    vertical-align: sub;
  }
`;

const CardFooter = styled.footer<{ size: CardSizes }>`
  display: grid;
  justify-items: center;
  align-items: end;
  padding-bottom: 0.3rem;
  grid-template-columns: ${props =>
    props.size === "m" ? "8fr 1fr 1fr" : "5fr 1fr 1fr"};
  width: 100%;
`;

const CardLink = styled.a`
  justify-self: start;
`;

export default ProjectCard;
