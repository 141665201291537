import React from "react";
import styled from "styled-components";
import colors from "../styles/colors";
import useScrollTo from "../hooks/useScrollTo";

export const StyledPage = styled.section`
  max-width: 100vw;
  min-height: 90vh;
  padding: 10vh 0.5em 0.5em;
  overflow-x: hidden;
  border-bottom: 2px solid #0002;

  @media (min-width: 650px) {
    padding: 10vh 1em 1em;
  }
`;

interface PageProps {
  id: string;
}

export const PageWrapper: React.FC<PageProps> = ({ id, children }) => {
  const pageRef = React.useRef<HTMLElement>(null);
  useScrollTo(id, pageRef);

  return (
    <StyledPage id={id} ref={pageRef}>
      {children}
    </StyledPage>
  );
};

export const PageTitle = styled.h2`
  color: ${colors.text.primary.dark};
  text-align: center;
`;
