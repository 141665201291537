import React from "react";
import { PageWrapper, PageTitle } from "../templates/page";

const About = () => {
  return (
    <PageWrapper id="about">
      <PageTitle>About</PageTitle>
    </PageWrapper>
  );
};

export default About;
