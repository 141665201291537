import React from "react";
import { PageWrapper } from "../templates/page";
import styled from "styled-components";
import ProjectCard from "../components/ProjectCard";
import { lagerstyring } from "../projectList";

const Home = () => {
  return (
    <PageWrapper id="home">
      {/* <HomeInfo/> */}
      <ProjectCard size="m" project={lagerstyring} />
    </PageWrapper>
  );
};

/* eslint-disable-next-line */
const HomeInfo = () => {
  return (
    <HomeContent>
      <MainText>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur.
      </MainText>
      <HomeImage />
      <HomeText>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </HomeText>
      <HomeImage />
      <HomeText>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </HomeText>
    </HomeContent>
  );
};

const HomeContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  place-content: center;
  max-width: 700px;
  margin: 0 auto;
`;

const HomeImage = styled.img`
  height: 80%;
  width: 80%;
`;

const HomeText = styled.p`
  padding: 1em;
  font-size: 0.7em;

  @media (min-width: 650px) {
    font-size: 0.9em;
  }
`;

const MainText = styled.p`
  padding: 2em 4em;
  grid-column: span 2;
  font-size: 0.8em;

  @media (min-width: 650px) {
    font-size: 1em;
  }
`;

export default Home;
