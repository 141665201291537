import { createGlobalStyle } from "styled-components";
import colors from "./colors";

const GlobalStyles = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    max-height: 100vh;
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: overlay;

    ::-webkit-scrollbar {
      height: 5px;
      background: #0001;
      z-index: 12;
    }

    ::-webkit-scrollbar-thumb {
      background: #3339;
      z-index: 12;
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
  }

  p {
    color: ${colors.text.secondary.dark}
  }

  a {
    font-size: 15px;
    color: ${colors.text.secondary.dark};
    text-decoration: none;

    :visited {
      color: ${colors.text.secondary.dark};
    }

    :hover {
      color: ${colors.text.hover.dark};
    }

    &.active {
      color: ${colors.text.visited.dark};
    }


    @media (min-width: 650px) {
      font-size: 20px;
    }

    @media screen and (prefers-color-scheme: light) {
      color: ${colors.text.secondary.light};

      :visited {
        color: ${colors.text.secondary.light};
      }

      :hover {
        color: ${colors.text.hover.light}
      }
    }

    svg {
      height: 25px;
      width: 25px;
    }
  }
`;

export default GlobalStyles;
