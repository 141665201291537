import React from "react";
import GlobalStyles from "./styles/global";
import styled from "styled-components";
import Title from "./components/Title";
import Nav from "./components/Nav";
import colors from "./styles/colors";
import Projects from "./pages/Projects";
import Blog from "./pages/Blog";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";

const App: React.FC = () => {
  return (
    <>
      <GlobalStyles />
      <PageWrapper>
        <Header>
          <Title />
          <Nav />
        </Header>
        <PageContent>
          <Home />
          <Projects />
          <About />
          <Contact />
          <Blog />
        </PageContent>
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.main`
  height: 100%;
  width: 100%;
  background-color: ${colors.background.primary.dark};
  display: grid;

  @media screen and (prefers-color-scheme: light) {
    background-color: ${colors.background.primary.light};
  }
`;

const PageContent = styled.div`
  margin-top: 10vh;
`;

const Header = styled.header`
  max-height: 10vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-style: italic;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 10;
  padding-bottom: 0.5em;
  background-color: ${colors.background.header.dark};
`;

export default App;
