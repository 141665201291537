import { LinkExternal } from "styled-icons/boxicons-regular/LinkExternal";
import { Github as GithubLogo } from "styled-icons/boxicons-logos/Github";
import { Code } from "styled-icons/boxicons-regular/Code";
import { CodeAlt } from "styled-icons/boxicons-regular/CodeAlt";

export default {
  LinkExternal,
  GithubLogo,
  Code,
  CodeAlt
};
