import React from "react";
import styled from "styled-components";
import colors from "../styles/colors";

const Title = () => {
  return (
    <TitleWrapper>
      <Name>
        {window.innerWidth > 450 && "eystein.dev"}(
        {window.innerWidth < 450 && "ey"})
      </Name>
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div`
  height: 100%;
  padding-left: 1.5em;
  display: flex;
  align-items: center;
  color: ${colors.text.primary.dark};

  @media (min-width: 650px) {
    width: 100%;
  }

  @media screen and (prefers-color-scheme: light) {
    color: ${colors.text.primary.light};
  }
`;

const Name = styled.h1`
  font-size: 1.5em;

  @media (min-width: 650px) {
    font-size: 2em;
  }
`;

export default Title;
