import React from "react";
import { PageWrapper, PageTitle } from "../templates/page";

const Blog = () => {
  return (
    <PageWrapper id="blog">
      <PageTitle>Blog</PageTitle>
    </PageWrapper>
  );
};

export default Blog;
