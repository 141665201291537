import React from "react";
import { PageWrapper } from "../templates/page";
import styled from "styled-components";
import ProjectCard from "../components/ProjectCard";
import { lagerstyring } from "../projectList";

const Projects = () => {
  return (
    <PageWrapper id="projects">
      <ProjectsList>
        <ProjectCard project={lagerstyring} size="m" />
        <ProjectCard project={lagerstyring} size="s" />
        <ProjectCard project={lagerstyring} size="s" />
      </ProjectsList>
    </PageWrapper>
  );
};

const ProjectsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2em;
  max-width: 500px;
  margin: 0 auto;

  & > :nth-child(1) {
    grid-column: span 2;
  }
`;

export default Projects;
